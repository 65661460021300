<template>
<div class="IndepenDent">
  <!--  导航-->
  <div class="dh">
    <div class="navigation">
      <div class="ico" @click="go()">
        <img class="icon" src="../assets/img15.png">
        <p class="designation">华钛信息</p>
      </div>
      <div class="selectivity">
        <div class="alternative">
          <div class="xzx1 xzxz1">
            <span class="xzxz" @click="hang()">行业方案<span class="xzl"></span></span>
            <div class="nav1 nav">
              <div class="navxx">
                <span>行业方案</span>
                <span>华钛信息致力于帮助各类型企业搭建贴合业务的数据平台目前服务的客户涵盖金融、互联网、制造业、零售业、汽车等多个行业。</span>
                <span @click="hang()">前往行业方案 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzData" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img188.png">
              </div>
            </div>
          </div>
          <div class="xzx2 xzxz1">
            <span class="xzxz" @click="zi()">自主产品 <span class="xzl"></span></span>
            <div class="nav2 nav">
              <div class="navxx">
                <span>自主产品</span>
                <span>华钛信息供应商采购平台以为企业降本增效、提高经营效率、提升数据价值为导向，含供应商管理、寻源管理、采购协同、财务协同等模块。</span>
                <span @click="zi()">前往自主产品 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzDatas" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img189.png">
              </div>
            </div>
          </div>
          <div class="xzx3 xzxz1">
            <span class="xzxz" @click="fu()">服务范围 <span class="xzl"></span></span>
            <div class="nav3 nav">
              <div class="navxx">
                <span>服务范围</span>
                <span>华钛信息以合作共赢的服务理念为核心，为企业提供优质化服务。</span>
                <span @click="fu()">前往服务范围 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzDataes" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img190.png">
              </div>
            </div>
          </div>
          <div class="xzx4 xzxz1" v-for="(item ,index) in newData" :key="index">
            <span class="xzxz" @click="din(item)">{{item.cname}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  第一层-->
  <div class="i-first">
   <div class="zss">
     <div class="i-nr">
       <span>自主产品</span>
       <span>华钛信息供应商采购平台以为企业降本增效、提高经营效率、提升数据价值为导向，含供应商<br>管理、寻源管理、采购协同、财务协同等模块。</span>
     </div>
   </div>
  </div>
<!--  tab标签-->
  <div class="tabs">
    <div class="tabxr">
      <ul>
        <li @click="cur=0">
          <p :class="{ active: cur == 0 }">供应商管理系统</p>
          <p :class="{ underline: cur == 0 }"></p>
        </li>
        <li @click="cur=1">
          <p :class="{ active: cur == 1 }">企业经营数字化平台</p>
          <p :class="{ underline: cur == 1 }"></p>
        </li>
      </ul>
    </div>
  </div>
<!--  tab选项-->
  <div v-show="cur==0" class="tabx">
<!--   第一个-->
    <div class="fi">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../assets/img3.png">
            <span>产品介绍</span>
            <img class="img4" src="../assets/img4.png">
          </div>
        </div>
      </div>
      <span class="xj">以采购管控为核心,协同管理为辅助,数据分析为支撑,搭建企业标准采购数字化平台,实现供应链上下游的数据互联互通,加强成本控制,提高采购管理效率。</span>
    </div>
<!--    第二个-->
    <div class="se">
      <div class="c-thirdy">
        <div class="spreadhead">
          <div class="subhead">
            <div class="moit">
              <img class="img3" src="../assets/img3.png">
              <span>产品能力</span>
              <img class="img4" src="../assets/img4.png">
            </div>
          </div>
        </div>
        <div class="box3">
          <div class="box4">
            <div class="outer" v-for="(item , index) in loopData" :key="index">
              <img class="dan1" :src="item.icon" />
              <span class="dan2">{{item.xi}}</span>
              <span class="dan3">{{item.xi1}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    第三个-->
    <div class="th">
      <div class="zss">
        <div class="c-thirdys">
          <div class="spreadhead">
            <div class="subhead">
              <div class="moit">
                <img class="img3" src="../assets/img3.png">
                <span>产品能力</span>
                <img class="img4" src="../assets/img4.png">
              </div>
            </div>
          </div>
          <div class="box3">
            <div class="box4">
              <div class="outer" v-for="(item , index) in joopData" :key="index">
                <img class="dan1" :src="item.icon" />
                <span class="dan2">{{item.xi}}</span>
                <span class="dan3">{{item.xi1}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    第四个-->
    <div class="fo">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../assets/img3.png">
            <span>产品案例</span>
            <img class="img4" src="../assets/img4.png">
          </div>
        </div>
      </div>
      <div class="gm">
        <img class="mg1" src="../assets/img116.png">
        <div class="dy">
          <span class="dy1">某北京龙头制造企业</span>
          <img class="mg2" src="../assets/img6.png">
          <span class="dy2">华钛信息为该企业提供一套完整的供应链采购端解决方案，从供应商管理、寻源管理、采购协同、财务协同等方面构建全方面的供应商管理平台</span>
          <span class="dy3" @click="fa()">查看详情
            <img class="mg3" src="../assets/img11.1.png">
          </span>
        </div>
      </div>
    </div>
  </div>
  <div v-show="cur==1" class="tabx">
    <!--   第一个-->
    <div class="fi">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../assets/img3.png">
            <span>产品介绍</span>
            <img class="img4" src="../assets/img4.png">
          </div>
        </div>
      </div>
      <span class="xj">通过全新技术架构取代企业原本以ERP为核心的成本归集和成本分析系统，通过全新的的数字化成本分析平台实现从性能和效率质的飞跃。</span>
    </div>
<!--    第二个-->
    <div class="se">
      <div class="c-thirdy">
        <div class="spreadhead">
          <div class="subhead">
            <div class="moit">
              <img class="img3" src="../assets/img3.png">
              <span>产品能力</span>
              <img class="img4" src="../assets/img4.png">
            </div>
          </div>
        </div>
        <div class="box3">
          <div class="box4">
            <div class="outer" v-for="(item , index) in goopData" :key="index">
              <img class="dan1" :src="item.icon" />
              <span class="dan2">{{item.xi}}</span>
              <span class="dan3">{{item.xi1}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    第三个-->
    <div class="th">
      <div class="zss">
        <div class="c-thirdys">
          <div class="spreadhead">
            <div class="subhead">
              <div class="moit">
                <img class="img3" src="../assets/img3.png">
                <span>产品能力</span>
                <img class="img4" src="../assets/img4.png">
              </div>
            </div>
          </div>
          <div class="box3">
            <div class="box4">
              <div class="outer" v-for="(item , index) in roopData" :key="index">
                <img class="dan1" :src="item.icon" />
                <span class="dan2">{{item.xi}}</span>
                <span class="dan3">{{item.xi1}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    第四个-->
    <div class="fo">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../assets/img3.png">
            <span>产品案例</span>
            <img class="img4" src="../assets/img4.png">
          </div>
        </div>
      </div>
      <div class="gm">
        <img class="mg1" src="../assets/img127.png">
        <div class="dy">
          <span class="dy1">某杭州上市制造企业</span>
          <img class="mg2" src="../assets/img6.png">
          <span class="dy2">华钛信息经营数字化平台帮助企业完成了整个成本分析系统的重新架构，通过新技术、新方案，客户的整个系统性能得到了质的飞跃</span>
          <span class="dy3" @click="ip()">查看详情
            <img class="mg3" src="../assets/img11.1.png">
          </span>
        </div>
      </div>
    </div>
  </div>
  <!--    底层-->
  <div class="ground-floor">
    <div class="fbox">
      <div class="fbox1">
        <div class="fbox2">
          <div class="lianxi">
            <div class="fico">
              <img class="ficon" src="../assets/img2.png">
              <p class="fdesignation">华钛信息</p>
            </div>
            <div class="contway">
              <div class="special">
                <span>联系电话：</span>
                <span>021-55962658</span>
              </div>
              <div class="special">
                <span>商务合作：</span>
                <span>business@huataish.com</span>
              </div>
            </div>
          </div>
          <div class="cut-line"></div>
          <div class="explain">
            <div class="explain1">
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">行业方案</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatas" :key="index">
                      <span @click="din(item)">{{ item.lei }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">自主产品</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatass" :key="index">
                      <span @click="din(item)">{{ item.lei1 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">服务范围</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatasss" :key="index">
                      <span @click="din(item)">{{ item.lei2 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="cl()">客户案例</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                      <span>{{ item.lei3 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="hz()">合作伙伴</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                      <span>{{ item.lei3 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="xx()">华钛信息</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataess" :key="index">
                      <span @click="din(item)">{{ item.lei4 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cut-line1"></div>
        <div class="safety">
          <span>上海总部: 上海市长宁区凯旋路1058号1-2号楼2楼</span>
          <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20008244号-1</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "IndepenDent",
  data() {
    return {
      cur: 0,
      hzData:[
        {
          hzz:"金融行业",
          url:'/FinAnce'
        },
        {
          hzz:"制造行业",
          url:'/Make'
        },
        {
          hzz:"零售行业",
          url:'/ReTail'
        },
        {
          hzz:"汽车行业",
          url:'/AutoMobile'
        },
      ],
      hzDatas:[
        {
          hzz:"供应商管理系统",
          url:'/IndepenDent'
        },
        {
          hzz:"企业数字化平台",
          url:'/IndepenDent'
        },
      ],
      hzDataes:[
        {
          hzz:"定制化开发服务",
          url:'/CustoMization'
        },
        {
          hzz:"产品化解决方案服务",
          url:'/Product'
        },
        {
          hzz:"平台研发",
          url:'/PlatForm'
        },
        {
          hzz:"咨询服务",
          url:'/DigiTization'
        },
        {
          hzz:"运维服务",
          url:'/OperAtion'
        },
        {
          hzz:"外包服务",
          url:'/Epiboly'
        },
      ],
      newData: [
        {
          cname: "客户案例",
          url:'/Clien'
        },
        {
          cname: "合作伙伴",
          url:'/Partner'
        },
        {
          cname: "公司信息",
          url:'/information'
        }
      ],
      loopData: [
        {
          icon: require("../assets/img106.png"),
          xi:"供应商管理",
          xi1:"供应商全生命周期模块，整合供应商数据自动化分析与管理供应商绩效",
        },
        {
          icon: require("../assets/img107.png"),
          xi:"寻源管理",
          xi1:"智能采购管理系统含有多寻源方案组合呈现，整合海量供应商资源",
        },
        {
          icon: require("../assets/img108.png"),
          xi:"采购协同",
          xi1:"订单协同灵活配置整合物流信息，精准定位质量协同溯源制造流程支撑复杂业务场景",
        },
        {
          icon: require("../assets/img109.png"),
          xi:"财务协同",
          xi1:"自动生成财务结算，缩短结算流程，财务全流程透明",
        },
        {
          icon: require("../assets/img110.png"),
          xi:"开放平台",
          xi1:"企业ERP对接经验丰富，自动管理企业接口，快速接入电商平台",
        },
        {
          icon: require("../assets/img110.png"),
          xi:"应用市场",
          xi1:"安全风控支持，多功能增值服务支持，市场数据驱动决策",
        },
      ],
      goopData:[
        {
          icon: require("../assets/img117.png"),
          xi:"中心数字化",
          xi1:"分布式新技术，成本朔源清晰，成本颗粒度细致",
        },
        {
          icon: require("../assets/img118.png"),
          xi:"计算",
          xi1:"高速成本计算，计算结果精准，透彻分解业务逻辑",
        },
        {
          icon: require("../assets/img119.png"),
          xi:"数据获取",
          xi1:"源数据，数据采集，数据调整/补录，数据配置",
        },
        {
          icon: require("../assets/img120.png"),
          xi:"分析输出",
          xi1:"财务报表输出，分摊分析输出，还原分析输出",
        },
        {
          icon: require("../assets/img121.png"),
          xi:"实施落地",
          xi1:"需求调研与匹配，方案设计与确认，数据与功能准备，培训与测试",
        },
        {
          icon: require("../assets/img122.png"),
          xi:"工具支持",
          xi1:"业务调研模板，通用功能清单，数据清单及模板",
        },
      ],
      foolDatas: [
        {
          lei: "金融行业",
          url: '/FinAnce'
        },
        {
          lei: "制造行业",
          url: 'Make'
        },
        {
          lei: "零售行业",
          url: '/ReTail'
        },
        {
          lei: "汽车行业",
          url:'/AutoMobile'
        },
      ],
      foolDatass: [
        {
          lei1: "供应商管理系统",
          url: '/IndepenDent'
        },
        {
          lei1: "企业经营数字化平台",
          url:'/IndepenDent'
        },
      ],
      foolDatasss: [
        {
          lei2: "定制化开发服务",
          url:'/CustoMization'
        },
        {
          lei2: "产品化解决方案服务",
          url:'/Product'
        },
        {
          lei2: "平台研发",
          url:'/PlatForm'
        },
        {
          lei2: "咨询服务",
          url:'/DigiTization'
        },
        {
          lei2: "运营服务",
          url:'/OperAtion'
        },
        {
          lei2: "外包服务",
          url:'/Epiboly'
        },
      ],
      foolDataes: [

      ],
      foolDataess: [

      ],
      joopData:[
        {
          icon: require("../assets/img112.png"),
          xi:"降本",
          xi1:"增加服务评价，降低使用成本，改善供方的交付质量，缩短交付周期",
        },
        {
          icon: require("../assets/img113.png"),
          xi:"增效",
          xi1:"提升协议采购占比，寻源快速响应，订单协同，送货协同",
        },
        {
          icon: require("../assets/img114.png"),
          xi:"经营",
          xi1:"采购战略指定，深化采购运营，采购管理水平提升，供应商分析",
        },
        {
          icon: require("../assets/img115.png"),
          xi:"数据",
          xi1:"风险管控，价格趋势，采购额分析",
        },
    ],
      roopData:[
        {
          icon: require("../assets/img123.png"),
          xi:"降本",
          xi1:"系统处理数据量大幅度上升",
        },
        {
          icon: require("../assets/img124.png"),
          xi:"增效",
          xi1:"系统性能大幅提升",
        },
        {
          icon: require("../assets/img125.png"),
          xi:"经营",
          xi1:"通过配置可实施需求",
        },
        {
          icon: require("../assets/img126.png"),
          xi:"数据",
          xi1:"所有计算结果均可追溯",
        },
      ],
    }
  },
  methods:{
    din(item){
      console.log(item.url)
      window.open(item.url,'_blank')
    },
    handleCommand(command) {
      window.open(command,'_blank')
    },
    hang(){
      window.open('/Solution','_blank')
    },
    zi(){
      window.open('/IndepenDent','_blank')
    },
    dian1(){
      window.open('/FinAnce','_blank')
    },
    dian2(){
      window.open('/Make','_blank')
    },
    dian3(){
      window.open('/ReTail','_blank')
    },
    dian4(){
      window.open('/AutoMobile','_blank')
    },
    go(){
      window.open('/','_blank')
    },
    fu(){
      window.open('/scope','_blank')
    },
    hz(){
      window.open('/Partner','_blank')

    },
    xx(){
      window.open('/information','_blank')

    },
    ip(){
      window.open('/IPO','_blank')

    },
    fa(){
      window.open('/Faucet','_blank')

    },
    cl(){
      window.open('/Clien','_blank')
    }
  }

}
</script>

<style scoped>
.IndepenDent{
  display: flex;
  flex-direction: column;
  margin: 0 auto 0;
  position: relative;
  width: 100%;
}
/*第一层*/
.i-first{
  width: 100%;
  /*min-width: 1920px;*/
  height: 526px;
  background-image: url("../assets/img38.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.i-first .i-nr{
  position: relative;
  top: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 665px;
  height: 136px;
}
.i-first .i-nr span:first-child{
  font-size: 38px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 53px;
}
.i-first .i-nr span:last-child{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
}
/*tab标签*/
.tabs{
  width: 100%;
  height: 66px;
  border-bottom: 1px solid #DDDDDD;
}
.tabs .tabxr{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 388px;
  height: 66px;
  margin: 0 auto;
}
.tabs .tabxr ul{
  display: flex;
  justify-content: space-between;
  width: 388px;
  height: 60px;
  line-height: 60px;
}
.tabs .tabxr ul li p:first-child{
  cursor: pointer;
  width: 162px;
  height: 60px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202B4E;
  line-height: 60px;
  text-align: center;
  margin-bottom: 4px;
}
.tabs .tabxr ul li p:first-child.active{
  font-weight: 500;
  color: #124BFF;
}
.tabs .tabxr ul li .underline{
  width: 56px;
  height: 3px;
  background: #124BFF;
  margin: 0 auto;
}
/*标题*/
.spreadhead {
  width: 100%;
  height: 122px;
  /*margin-bottom: 48px;*/
  /*background: red;*/
}

.spreadhead .subhead {
  display: inline-block;
  width: 100%;
  height: 24px;
  /*background: white;*/
  margin-top: 64px;
  margin-bottom: 12px;
}

.spreadhead .subhead .moit {
  display: flex;
  justify-content: space-between;
  width: 272px;
  height: 24px;
  margin: 0 auto;
  /*background: #124BFF;*/
  font-size: 24px;
  line-height: 24px;
}

.spreadhead .subhead .moit img {
  width: 64px;
  height: 18px;
  margin-top: 3px;
}

.spreadhead .subhead .moit span {
  display: block;
  width: 96px;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  overflow-wrap: break-word;
}
.spreadhead .details {
  display: block;
  /*display: inline-block;*/
  width: 476px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  color: #666F8F;
  margin: auto;
}
/*tab选项*/
.tabx{
  width: 100%;
  height: 1642px;
  display: flex;
  flex-direction: column;
}
.tabx .fi{
  width: 100%;
  height: 284px;
  display: flex;
  flex-direction: column;
}
.tabx .fi .xj{
  width: 980px;
  height: 76px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(32, 43, 78, 1);
  font-size: 18px;
  letter-spacing: 1px;
  font-family: PingFangSC-Regular;
  line-height: 38px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}
.c-thirdy{
  width: 100%;
  height: 548px;
  background: #F9FAFF;
}
.c-thirdys{
  width: 100%;
  height: 375px;
}
.c-thirdy .box3 {
  width: 100%;
  height: 562px;
  margin-top: 48px;
}
.c-thirdy .box4 {
  display: flex;
  flex-direction: row;
  width: 1053px;
  /*height: 370px;*/
  margin: 0 auto;
  flex-wrap: wrap;
}
.c-thirdy .box4 .outer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 163px;
  border-radius: 4px;
  width: 250px;
  margin: 0 150px 20px 0;
}
.c-thirdy .box4 .outer .dan1{
  display: block;
  width: 44px;
  height: 46px;
  margin: 0 auto;
}
.c-thirdy .box4 .outer .dan2{
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #202B4E;
  line-height: 25px;
  margin: 0 auto;
}
.c-thirdy .box4 .outer .dan3{
  width: 250px;
  height: 59px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 111, 143, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.c-thirdy .outer:nth-child(3n) {
  margin-right: 0;
}
.c-thirdy .outer:nth-last-child(-n + 3) {
  margin-bottom: 0;
}
.c-thirdys .box3 {
  width: 100%;
  margin-top: 48px;
}
.c-thirdys .box4 {
  display: flex;
  justify-content: space-between;
  /*height: 370px;*/
  margin: 0 auto;
  flex-wrap: wrap;
}
.c-thirdys .box4 .outer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 163px;
  border-radius: 4px;
  width: 250px;
}
.c-thirdys .box4 .outer .dan1{
  display: block;
  width: 44px;
  height: 46px;
  margin: 0 auto;
}
.c-thirdys .box4 .outer .dan2{
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #202B4E;
  line-height: 25px;
  margin: 0 auto;
}
.c-thirdys .box4 .outer .dan3{
  width: 250px;
  height: 59px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(102, 111, 143, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.c-thirdys .outer:last-child{
  margin-right: 0;
}
.tabx .th{
  width: 100%;
  height: 375px;
  display: flex;
  flex-direction: column;
}
.tabx .fo{
  width: 100%;
  height: 435px;
  display: flex;
  flex-direction: column;
  background-color: #F9FAFF;
}
.tabx .fo .gm{
  width: 1176px;
  height: 257px;
  display: flex;
  background-color: white;
  margin: 0 auto;
}
.tabx .fo .gm .mg1{
  display: block;
  width: 480px;
  height: 257px;
}
.tabx .fo .gm .dy{
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 46px 36px 24px;
}
.tabx .fo .gm .dy .dy1{
  width: 216px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #202B4E;
  line-height: 33px;
}
.tabx .fo .gm .dy .dy2{
  width: 620px;
  height: 50px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202B4E;
  line-height: 28px;
  margin-top: 24px;
}
.tabx .fo .gm .dy .dy3{
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #124BFF;
  line-height: 22px;
  margin-top: 52px;
}
.tabx .fo .gm .dy .dy3:hover img{
  left: 8px;
}
.tabx .fo .gm .dy .dy3 img{
  width: 14px;
  height: 14px;
  display: inline-block;
  position: relative;
  top: 2px;
  transform: all 0.5s;
}
.tabx .fo .gm .mg2{
  display: block;
  width: 26px;
  height: 6px;
  margin-top: 10px;
}

</style>
<style>
.el-dropdown {
  color: black;
  line-height: 60px;
  font-size: 16px;
  margin-right: 74px;
}

.el-button.is-round {
  margin-top: 16px;
  width: 88px;
  text-align: center;
  padding: 0px;
  height: 36px;
  /*background-color: rgba(18, 75, 255, 1);*/
}

.el-carousel__item img {
  width: 100%;
  line-height: 200px;
  margin: 0;
}

.el-carousel__arrow--left {
  border: 1px solid #124BFF;
}

.el-carousel__arrow--right {
  border: 1px solid #124BFF;
}

.el-carousel__arrow i {
  color: white;
}
</style>